// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: "##production##",
  apiEndpoint: "##apiEndpoint##",
  goalTrackerLink: "##goalTrackerLink##",
  postLogoutRedirect: "##postLogoutRedirect##/",
  webApiClientId: "##webApiClientId##",
  tenant: "##tenant##",
  CookiesPolicyLink: "##CookiesPolicyLink##",
  HomePageLink: "##HomePageLink##",
  roles: {
    FeedbackAdmin: "##roles.FeedbackAdmin##",
    FeedbackUser: "##roles.FeedbackUser##",
    FeedbackCm: "##roles.FeedbackCm##",
    FeedbackReviewer: "##roles.FeedbackReviewer##",
    FeedbackCmDelegate: "##roles.FeedbackCmDelegate##",
    FeedbackCFRAdmin: "##roles.FeedbackCFRAdmin##",
    FeedbackPeopleCare: "##roles.FeedbackPeopleCare##",
    FeedbackMarketing: "##roles.FeedbackMarketing##",
    FeedbackLead: "##roles.FeedbackLead##",
    FeedbackCEO: "##roles.FeedbackCEO##",
    FeedbackDirector: "##roles.FeedbackDirector##",
  },
  autoSaveInterval: 300000, // 5 minutes
  assessmentFiscalYear: {
    StartDay: "##assessmentFiscalYear.StartDay##",
    StartMonth: "##assessmentFiscalYear.StartMonth##",
    EndDay: "##assessmentFiscalYear.EndDay##",
    EndMonth: "##assessmentFiscalYear.EndMonth##",
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
